import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import CardSection from "../../components/CardSection";
import Form from "../../components/Form/Form";
import { Element } from "react-scroll";
import {
  EnvelopeSimple as EnvelopeSimpleIcon,
  TwitterLogo as TwitterLogoIcon,
  LinkedinLogo as LinkedinLogoIcon,
} from "@phosphor-icons/react";
import ProductSection from "../../components/ProductSection";
import config from "../../config";

const mobileSocials = [
  {
    id: "twitter",
    content: (
      <a target="_blank" href="https://twitter.com/Remaster_io">
        <TwitterLogoIcon size={24} weight="light" />
      </a>
    ),
  },
  {
    id: "linkedin",
    content: (
      <a target="_blank" href="https://www.linkedin.com/company/remasterlegal">
        <LinkedinLogoIcon size={24} weight="light" />
      </a>
    ),
  },
];

const Home = () => {
  return (
    <div>
      <Navbar />
      <main>
        <Element name="home">
          <Banner />
          <CardSection />
        </Element>

        <Element name="products">
          <ProductSection />
        </Element>
        <Element name="contact" className="bg-black">
          <footer className="container text-white pt-20 xl:pt-24">
            <div className="sm:grid sm:grid-cols-12 sm:gap-8">
              <div className="sm:col-span-5">
                <h2 className="mb-4">Let's REMASTER Contracts Together</h2>
                <p className="flex gap-1 items-center text-rms-grey-light mb-20">
                  <EnvelopeSimpleIcon size={24} />
                  <a href="mailto:abc@example.com">info@remaster.io</a>
                </p>
              </div>
              <div className="sm:col-span-6 sm:col-start-7 xl:col-span-5 xl:col-start-8">
                <Form />
              </div>
            </div>

            <div className="flex flex-col xl:flex-row-reverse xl:justify-between gap-4 py-10 sm:pt-16 xl:pt-10">
              <hr className="block sm:hidden border-rms-grey-light" />
              <p className="sm:hidden sm:text-base text-center text-rms-grey-light">
                <span>Follow us on social media</span>
                <ul className="flex gap-8 justify-center mt-4">
                  {mobileSocials.map((social) => (
                    <li key={social.id}>{social.content}</li>
                  ))}
                </ul>
              </p>
              <hr className="block sm:hidden border-rms-grey-light" />
              <p className="sm:text-base text-center text-rms-grey-light">
                {[
                  {
                    title: "Privacy Policy",
                    to: [config.infoUrl, "/privacy"].join(""),
                  },
                  {
                    title: "Terms of Service",
                    to: [config.infoUrl, "/terms"].join(""),
                  },
                  {
                    title: "SaaS",
                    to: [config.infoUrl, "/saas"].join(""),
                  },
                ].map((tile, index) => (
                  <React.Fragment key={tile.title}>
                    {!!index && " | "}
                    <Link to={tile?.to}>{tile.title}</Link>
                  </React.Fragment>
                ))}
              </p>
              <hr className="block sm:hidden border-rms-grey-light" />
              <p className="sm:text-base text-center text-rms-grey-light">
                Remaster Inc. © 2022 - 2024 | All Rights Reserved
              </p>
            </div>
          </footer>
        </Element>
      </main>
    </div>
  );
};

export default Home;
