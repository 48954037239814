import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import { MarketplaceBanner } from "../../components/Banner";
import { Element } from "react-scroll";
import { JoinUsForm } from "../../components/Form/Form";
import classNames from "classnames";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import {
  TwitterLogo as TwitterLogoIcon,
  LinkedinLogo as LinkedinLogoIcon,
} from "@phosphor-icons/react";
import config from "../../config";

gsap.registerPlugin(ScrollTrigger);

const mobileSocials = [
  {
    id: "twitter",
    content: (
      <a target="_blank" href="https://twitter.com/Remaster_io">
        <TwitterLogoIcon size={24} weight="light" />
      </a>
    ),
  },
  {
    id: "linkedin",
    content: (
      <a target="_blank" href="https://www.linkedin.com/company/remasterlegal">
        <LinkedinLogoIcon size={24} weight="light" />
      </a>
    ),
  },
];

const works = {
  seller: [
    {
      title: "Create Legal Templates",
      desc: "Setup variables and conditions for your contract.",
      image: "./works/seller/work1.webp",
    },
    {
      title: "Upload Your IP",
      desc: "Democratize access for licensees.",
      image: "./works/seller/work2.webp",
    },
    {
      title: "List Your License",
      desc: "Create a bespoke licensing ecosystem",
      image: "./works/seller/work3.webp",
    },
    {
      title: "Licensor Approval",
      desc: "Review Licensed Products before production.",
      image: "./works/seller/work4.webp",
    },
    {
      title: "License Management Dashboard",
      desc: "Increase efficiency by tracking metrics and obligations in one place.",
      image: "./works/seller/work5.webp",
    },
  ],
  buyer: [
    {
      title: "Search for IP",
      desc: "Discover new content to license",
      image: "./works/buyer/work1.webp",
    },
    {
      title: "Purchase a License",
      desc: `Eliminate contract negotiations through "buy it now" productized licenses.`,
      image: "./works/buyer/work2.webp",
    },
    {
      title: "Submit Licensed Product Designs",
      desc: "Upload pre-production mock-up for licensors to approve.",
      image: "./works/buyer/work3.webp",
    },
    {
      title: "Sell Licensed Products",
      desc: "Sell officially licensed items on your e-commerce site.",
      image: "./works/buyer/work4.webp",
    },
    {
      title: "Royalty Payouts",
      desc: "Share reports and stay on top of royalties and obligations",
      image: "./works/buyer/work5.webp",
    },
  ],
};

const Marketplace = () => {
  const [activeTab, setActiveTab] = useState("seller");
  const [isSubmit, setIsSubmit] = useState(false);

  const onSubmit = () => {
    setIsSubmit(true);
  };

  const scrollTriggerRef = useRef();
  const containerRef = useRef();
  const tabContainerRef = useRef();

  useLayoutEffect(() => {
    const container = containerRef.current;
    const tabContainer = tabContainerRef.current;

    const ctx = gsap.matchMedia();

    ctx.add("(max-width: 767px)", () => {
      gsap.to(tabContainer, {
        scrollTrigger: {
          trigger: tabContainer,
          pin: true,
          pinSpacing: false,
          invalidateOnRefresh: true,
          // markers: true,
          start: () => `top +${76}`,
          end: () => `+=${container.offsetHeight}`,
        },
      });
    });
    ctx.add("(min-width: 768px)", () => {
      const tiles = gsap.utils.toArray(".work-tile");
      const texts = gsap.utils.toArray(".work-tile-text");

      gsap.to(tabContainer, {
        scrollTrigger: {
          trigger: tabContainer,
          pin: true,
          pinSpacing: false,
          invalidateOnRefresh: true,
          // markers: true,
          start: () =>
            `top +${
              (window.innerHeight -
                tiles[0].offsetHeight +
                76 -
                (window.innerWidth < 1280
                  ? container.offsetTop -
                    tabContainer.offsetTop +
                    tabContainer.offsetHeight
                  : 0)) /
              2
            } }`,
          end: () =>
            `+=${
              container.offsetHeight -
              tiles[tiles.length - 1].offsetHeight -
              tiles[tiles.length - 1].offsetHeight / 2
            }`,
        },
      });

      tiles.forEach((tile, i) => {
        const image = tile.querySelector(".work-tile-image");
        const text = tile.querySelector(".work-tile-text");

        if (i) {
          gsap.set(image, { opacity: 0 });
          gsap.set(text, { opacity: 0 });
        }

        const isLast = i === tiles.length - 2;
        const isActualLast = i === tiles.length - 1;

        if (!isActualLast) {
          gsap.to(image, {
            scrollTrigger: {
              trigger: image,
              pin: true,
              pinSpacing: false,
              id: i,
              start: () =>
                `top +${(window.innerHeight - image.offsetHeight + 76) / 2}`,
              end: () => `+=${image.offsetHeight / (isLast ? 2 : 1)}`,
              scrub: true,
              // markers: true,
              invalidateOnRefresh: true,
              onEnter: () => {
                gsap.to(image, { opacity: 1, duration: 0 });
                gsap.to(text, { opacity: 1 });
              },
              onEnterBack: () => {
                gsap.to(image, { opacity: 1, duration: 0 });
                gsap.to(text, { opacity: 1 });
              },
              onLeave: () => {
                if (!isLast) {
                  gsap.to(image, { opacity: 0, duration: 0 });
                  gsap.to(text, { opacity: 0 });
                }
              },
              onLeaveBack: () => {
                if (i) {
                  gsap.to(image, { opacity: 0, duration: 0 });
                  gsap.to(text, { opacity: 0 });
                }
              },
            },
          });
        }
      });
    });

    return () => ctx.revert();
  }, [activeTab]);

  return (
    <div>
      <Navbar type="marketplace" />
      <Element name="home">
        <MarketplaceBanner />
      </Element>
      <Element name="how-it-works" className="bg-black">
        <section className="container py-20 xl:py-36" ref={scrollTriggerRef}>
          <h1 className="text-white mb-2">HOW IT WORKS</h1>
          <p className="text-rms-grey-light mb-0 xl:mb-36">
            Build your catalogue, Monetize on ‘autopilot’, Automate enforcement.
          </p>
          <div className="xl:grid xl:grid-cols-12 xl:gap-8">
            <div
              className={classNames(
                "md:grid md:grid-cols-12 xl:grid-cols-1 gap-8 xl:col-span-2 h-max",
                "py-10 xl:py-0 bg-black md:bg-transparent z-10"
              )}
              ref={tabContainerRef}
            >
              <div className="p-2 border border-white grid grid-cols-2 xl:grid-cols-1 gap-2 md:col-span-6 md:col-start-7 xl:col-span-1 xl:col-start-1">
                {[
                  { id: "seller", title: "SELLER" },
                  { id: "buyer", title: "BUYER" },
                ].map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => {
                      if (
                        scrollTriggerRef.current.getBoundingClientRect().top < 0
                      ) {
                        const top = scrollTriggerRef.current.offsetTop;
                        window.scrollTo({ top: top + 76 });
                      }
                      setActiveTab(tab.id);
                    }}
                    className={classNames(
                      "px-8 py-2",
                      tab.id === activeTab
                        ? "bg-white text-black"
                        : "bg-black text-white"
                    )}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
            </div>
            <div
              className="flex flex-col gap-10 md:gap-0 xl:col-span-10"
              ref={containerRef}
            >
              {[...works[activeTab], {}].map((tile, i) => (
                <div
                  key={`${tile.title}-${i}`}
                  className={classNames(
                    "work-tile md:grid md:grid-cols-12 gap-8 xl:ml-10",
                    i === works[activeTab].length && "hidden"
                  )}
                >
                  <div className="work-tile-image aspect-[3/2] w-full mb-10 md:mb-0 md:col-span-6 md:col-start-7 md:row-start-1">
                    {tile.image && (
                      <img
                        alt="Feature Screenshot"
                        className="object-cover w-full h-full"
                        src={tile.image}
                      />
                    )}
                  </div>
                  <div
                    className={classNames(
                      "work-tile-text md:col-span-6 md:col-start-1 md:row-start-1 md:flex md:flex-col",
                      "md:justify-end"
                    )}
                  >
                    <h3 className="text-white xl:mt-10">{tile.title}</h3>
                    <p className="text-rms-grey-light">{tile.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Element>
      <Element name="join">
        <footer className="container pt-20 xl:pt-24">
          <div className="sm:grid sm:grid-cols-12 sm:gap-8">
            <div className="sm:col-start-3 sm:col-span-8">
              <JoinUsForm />
            </div>
          </div>
          <div className="flex flex-col xl:flex-row-reverse xl:justify-between gap-4 py-10 sm:pt-16 xl:pt-10">
            <hr className="sm:hidden text-rms-grey-dark" />
            <p className="sm:hidden sm:text-base text-center text-rms-grey">
              <span>Follow us on social media</span>
              <ul className="flex gap-8 justify-center mt-4">
                {mobileSocials.map((social) => (
                  <li key={social.id}>{social.content}</li>
                ))}
              </ul>
            </p>
            <hr className="block sm:hidden" />
            <p className="sm:text-base text-center text-rms-grey">
              {[
                {
                  title: "Privacy Policy",
                  to: [config.infoUrl, "/privacy"].join(""),
                },
                {
                  title: "Terms of Service",
                  to: [config.infoUrl, "/terms"].join(""),
                },
                {
                  title: "SaaS",
                  to: [config.infoUrl, "/saas"].join(""),
                },
              ].map((tile, index) => (
                <React.Fragment key={tile.title}>
                  {!!index && " | "}
                  <Link to={tile?.to}>{tile.title}</Link>
                </React.Fragment>
              ))}
            </p>
            <hr className="block sm:hidden" />
            <p className="sm:text-base text-center text-rms-grey">
              Remaster Inc. © 2022 - 2024 | All Rights Reserved
            </p>
          </div>
        </footer>
      </Element>
    </div>
  );
};

export default Marketplace;
